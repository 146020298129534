<template>
  <div class="documents-dashboard">
    <b-row>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="FolderIcon"
          :statistic="docsStats.folders"
          statistic-title="Total Folders"
          @click="goTo('folders')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="FileIcon"
          color="warning"
          :statistic="docsStats.files"
          statistic-title="Total Files"
          @click="goTo('folders')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="FileMinusIcon"
          color="danger"
          :statistic="docsStats.trash"
          statistic-title="Trash Files"
          @click="goTo('trash')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },
  data() {
    return {

    }
  },
  computed:{
    docsStats(){
      return this.$store.getters['documentsDashboard/docsStats']
    }
  },
  mounted() {
    this.$store.dispatch('documentsDashboard/getDocsStats')
  },
  methods:{
    goTo(name){
      this.$router.push({name})
    }
  }
}
</script>
